.supabase-auth {
  max-width: 400px;
  margin: 100px auto;
}

.chatbot {
  font-family: monospace;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  background: #f5f8fb;
  text-align: center;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.header {
  padding: 20px;
  text-align: center;
  color: #1e2c2f;
  max-height: 58px;
  font-size: 16px;
  font-weight: bold;
  background-color: #1e2c2f;
}

.logout-button {
  position: absolute;
  right: 10px;
  top: 18px;
  padding: 10px;
  font-size: 14px;
  background-color: transparent;
  color: #07ebb4;
  border: none;
  cursor: pointer;
}

.loading-animation {
  height: 100%;
  width: 40px;
}

.messages {
  width: 100%;
  height: calc(100vh - 145px);
  overflow: scroll;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
}

.message-container {
  width: 100%;
}

.bot-message {
  float: left;
  text-align: left;
  padding: 15px 20px;
  margin: 5px;
  border-radius: 20px 20px 20px 1px;
  background: #07ebb4;
  min-width: 40px;
  white-space: pre-line;
}

.bot-message-loading {
  padding: 5px 20px;
}

.user-message {
  float: right;
  padding: 15px 10px;
  margin: 10px;
  border-radius: 20px 20px 1px 20px;
  background: #cccccc;
  color: black;
}

.input {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.input > form > input[type="text"] {
  font-family: monospace;
  font-size: 16px;
  border: 0;
  border-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #eee;
  box-shadow: none;
  box-sizing: border-box;
  opacity: 1;
  outline: none;
  padding: 16px 52px 16px 10px;
  width: 100%;
  -webkit-appearance: none;
}

.input > form > input:focus {
  outline: none;
}

.input > form > button {
  background-color: transparent;
  border: 0;
  border-bottom-right-radius: 10px;
  box-shadow: none;
  cursor: pointer;
  fill: #4a4a4a;
  opacity: 1;
  outline: none;
  padding: 14px 16px 12px 16px;
  position: absolute;
  right: 0;
  top: 0;
}
